.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}

.btn:focus {
  outline: none;
}

.btn-xs {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.badge {
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-color: #777;
  border-radius: 10px;
}

.bg-green {
  background-color: #00a65a !important;
  color: #fff !important;
}

.bg-danger {
  background-color: #dd4b39;
  color: #fff;
}

.bg-primary {
  background-color: #3c8dbc !important;
  color: #fff !important;
}

.upload-image {
  max-width: 240px !important;
}

.image-default-width {
  width: 100px;
}

.image-dialog-width {
  max-width: 100%;
  display: block;
  margin: auto;
}

.dialog-form {
  min-width: 330px;
}

.err-text {
  color: #f44336;
  margin: 4px 14px 0 14px;
  font-size: 12px;
}

.ra-input > div {
  width: 100%;
}

.pointer {
  cursor: pointer;
}

#main-content {
  position: relative;
}

.air_info {
  font-size: 14px;
}

.air_status {
  font-weight: 900;
}

#map {
  width: 100%;
  height: 100%;
}

.search {
  display: flex;
  align-items: center;
  height: 60px;
}

.search > div:nth-child(1) {
  margin-left: 20px;
}

.search > div:nth-child(2) {
  margin-left: 20px;
}

.search > div {
  margin-right: 20px;
}

.search input {
  height: 25px;
}

.select-box {
  display: flex;
  z-index: 99999;
  position: absolute !important;
  top: 20px;
  left: 70px;
}

.type-select {
  max-width: 130px;
  margin-right: 20px;
  display: none;
}

.area-select {
  max-width: 130px;
}

.green {
  color: #25aa22
}

.gold {
  color: #ffd326
}

.orange {
  color: #ca8428
}

.red {
  color: #c9243b
}

.purple {
  color: #9a26ca
}

.grey {
  color: #8a0315
}

.content {
  display: flex;
  align-items: center;
}

.content > div {
  margin-right: 10px;
}

.content > div > sub {
  font-size: .75em;
}

.content > div:nth-child(2) {
  font-size: 20px;
  font-weight: 500;
}

.video-wrapper {
  display: "flex";
  flex-wrap: "wrap";
  flex-direction: "row";
}

.video-monitorWrapper {
  padding: "20px 10px 0 0";
  width: "300px";
  height: "300px";
}

.video-monitorWrapper > div {
  height: "100%",
}

.view {
  background-color: #fff;
  border: none;
  color: #0088ff;
}

.video-js {
  margin-bottom: 10px;
}

.edit-page > .MuiToolbar-root {
  display: none;
}

.city-select {
  margin-right: 10px;
}